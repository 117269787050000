import React from 'react'
import {Card, Container, Button} from "react-bootstrap";
import {Link} from "react-router-dom";

export const NotFound: React.FC = () => <>
    <Container>
        <Card>
            <Card.Body>
                <Card.Title>Not Found</Card.Title>
                <Card.Text>Sorry, we could not find that page.</Card.Text>
                <Button as={Link} to='/'>Home</Button>
            </Card.Body>
        </Card>
    </Container>
</>;
