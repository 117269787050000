import React from 'react'
import {Container} from "react-bootstrap";
import {Helmet} from "react-helmet";

export const Contact: React.FC = () => {
    const frameStyle = {width: '100%', height: '800px'}

    return <>
        <Helmet>
            <title>Barrett Consulting Limited - Contact</title>
            <link rel="canonical" href="https://www.barrett-consulting.co.uk/contact" />
        </Helmet>

        <Container>
            <h1>Contact</h1>
            <p>If you would like to get in touch please enter your enquiry below and we will get back to you.</p>
            <iframe title="Contact Form"
                    src="https://docs.google.com/forms/d/e/1FAIpQLSf4GSmy8X69t6btsOh-5Nzvmd7rpL7ie7en2jEYNsMMngLkBA/viewform?embedded=true"
                    style={frameStyle} frameBorder="0" marginHeight={0} marginWidth={0}>Loading…
            </iframe>
        </Container>
    </>;
}
