import {BlogPage} from "./BlogPage";
import React from "react";

export const CodeCoverageVsTestCoverage: React.FC = () => <BlogPage canonical="codecoveragevstestcoverage" title='Code coverage vs test coverage'>
    <p><i>Originally posted in 2013.</i></p>

    <p>A little while ago I was told "the code coverage is very good, so you should have no problems". As a big fan of
        test driven development this set off a few alarm bells because in my experience the more the developers
        concentrate on getting a high percentage out of their code coverage reports the worse the test coverage actually
        is. This seems somewhat counter-intuitive so I thought I'd explore it a bit.</p>

    <p>When doing test driven development I very rarely look at the code coverage reports my CI server faithfully churns
        out because I already know the code coverage is going to be very high. I know this because I never write
        production code except in response to seeing a test fail. This actually tells me something much more valuable
        than what the code coverage report tells me - not only do I know that my code will have been executed in a unit
        test, I know that there is a test assertion which will fail if any line of my code is removed.</p>

    <p>The converse of this is that when a developer has written some production code and wants to make sure the code
        coverage is good, it's very easy to write tests which execute all the code but almost impossible to be sure your
        tests assert on all the behaviour of the code. This is exactly what I found when I started work on the code
        referred to in the quote at the start of this post - I found I could remove significant features from the code
        without any tests actually failing. Good code coverage is NOT the same as good test coverage!</p>

</BlogPage>;