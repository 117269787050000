import {BlogPage} from "./BlogPage";
import React from "react";
import {Table} from "react-bootstrap";

export const TheValueOfWhy: React.FC = () => <BlogPage canonical="thevalueofwhy" title='The value of "Why?'>
    <p>As the father two inquisitive boys I often have to field "Why?" questions, and when you're trying to get
        something done or hoping they will complete their homework sometime this century they can be very frustrating.
        As a developer of computer software I often find myself on the other end of the same process, asking question
        after question starting with "Why?" to an increasingly frustrated representative of "The Business" - whichever
        business it might be at any given time. I like to think of myself as a rational and reasonable person so being
        on both ends of the same argument is not a comfortable position - I need to take a position and either encourage
        the questioning from my boys or stop behaving like them when I am working.</p>
    <p>To get started I need to ask myself two questions: why do I find "Why?" questions frustrating? Why do I ask
        "Why?" questions?</p>

    <p>Oh no - I've just asked myself two "Why?" questions! How should I feel about that? I'll list some reasons for
        finding such questions frustrating:</p>

    <ul>
        <li>I don't have time to explain this right now, and we need to get your homework done so you can hand it in;
        </li>
        <li>It's rather a long time since I was at school - I can't remember the answer to this, or at least not well
            enough to explain it to a primary-school pupil;
        </li>
        <li>I've always done it this way; I don't have a reason "why";</li>
        <li>I'm sure your teachers must have explained this at school.</li>
    </ul>

    <p>Now some reasons for asking "Why?" questions myself:</p>

    <ul>
        <li>I'm being presented with a solution rather than a problem, and I can't work out what problem we're trying to
            solve with it;
        </li>
        <li>Unless I understand why I am doing something I am unlikely to come up with a simple, maintainable and
            understandable implementation;
        </li>
        <li>If I don't understand why I am making a change in one area I am unlikely to spot when other areas need to
            change for the same reason.
        </li>
    </ul>

    <p>I have to say that the reasons I gave for finding "Why?" questions frustrating all sound a bit lame. In fact they
        all sound like the sort of cop-outs that would make me ask more "Why?" questions if I was at work. I wonder if I
        can rephrase them in a work context?</p>

    <ul>
        <li>We have a deadline! Just write the code;</li>
        <li>I'm just doing what my boss told me, I don't actually understand what we're trying to do;</li>
        <li>We've always done it this way;</li>
        <li>Don't we have Business Analysts for this sort of thing?</li>
    </ul>

    <p>Well that looks like a list of reasons that's asking to be refuted!</p>
    <Table bordered>
        <thead>
        <tr>
            <th>Reason for frustration</th>
            <th>Refutation</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>We have a deadline! Just write the code.</td>
            <td>Quickly writing code without understanding what we are doing is likely going to delay the release with
                bug-fixing rather than speed things up.
            </td>
        </tr>
        <tr>
            <td>I'm just doing what my boss told me, I don't actually understand what we're trying to do.</td>
            <td>If you don't understand what you're explaining to me then we're engaged in a giant game of Chinese
                whispers. Your boss is unlikely to be pleased when it goes wrong so they either need to make you
                understand this properly or they need to explain it to me directly.
            </td>
        </tr>
        <tr>
            <td>We've always done it this way.</td>
            <td>Either there is a good reason or there is not. If you don't know the reason you are not the right person
                to be making a decision. If you don't have the authority to change anything you are not the right person
                to be making a decision.
            </td>
        </tr>
        <tr>
            <td>Don't we have Business Analysts for this sort of thing?</td>
            <td>If you are abdicating responsibility you should abdicate authority too, otherwise there will be someone
                with responsibility but no authority and that never works.
            </td>
        </tr>
        </tbody>
    </Table>
    <p>All of these refutations look like they translate pretty well back onto my frustrating homework sessions with the
        boys, so it looks like I'll just have to suck up the questions and get on with answering them as best I can. On
        the plus side I can continue to ask the question "Why?" when I am working without the nagging feeling that I'm
        doing something I shouldn't.</p>

    <p>The question "Why?" is the key to understanding. For my sons, rote learning without understanding "Why?" will not
        enable them to apply their new skill in unknown situations. As a programmer unthinking implementation without
        understanding "Why?" always ends up with verbose, flaky and hard-to-maintain code.</p>
</BlogPage>;