import {BlogPage} from "./BlogPage";
import React from "react";

export const MongoDbSlavesAreNotOk: React.FC = () => <BlogPage canonical="mongodbslavesarenotok" title='MongoDB - slaves are not OK, and neither is using deprecated options...'>
    <p><i>Originally posted in 2012.</i></p>

    <p>Over the last few days I have been investigating a problem which we observed only when we ran our application in
        a pre-production environment, not on any of our local development and test machines. The problem appeared to be
        that MongoDB (of which I am a fan) had some kind of bug with it's inheritance of write concern. Here is the
        scenario (at least the relevant bits)</p>

    <p>DB db = new MongoURI(connectionString)</p>

    <p>db.setWriteConcern(WriteConcern.SAFE)</p>

    <p>DBCollection collection = db.getCollection('someCollection')</p>

    <p>collection.insert(new BasicDBObject([_id: 'foo']))</p>

    <p>collection.findOne(queryMap, null, ReadPreference.PRIMARY)</p>

    <p>This worked fine on our local boxes. All our writes are safe writes and the read in question is a read from the
        primary server. Assuming that the insert did not throw an exception the read must work, right? Actually wrong,
        but not in any of the ways we expected.</p>

    <p>We spent quite a lot of time agonising over the fact that safe writes were being set both in the connection
        string the app was configured with and by our code, and trying to figure out why this was not being inherited by
        the insert and thinking about setting the WriteConcern explicitly in the insert statement as a workaround. The
        actual problem was far simpler.</p>

    <p>Our read quite correctly in this case specified the read preference as PRIMARY so that we could be sure to find
        the record that had been inserted (the actual code was an upsert, but the problem is most obvious when this was
        resulting in an insert rather than an update). But the connection string we were being passed had slaveOk=true
        in it which is a deprecated option in MongoDB. SlaveOK has been replaced by ReadPreference.SECONDARY, which is
        what we wanted so we thought we were OK, but the big difference is that if you have set slaveOk then you can set
        ReadPreference.PRIMARY until you are blue in the face, but the MongoDB Java Driver ignores it!</p>

    <p>Once we worked this out the solution was obvious, remove slaveOk=true from the connection string and
        programatically call db.readPreference = ReadPreference.SECONDARY (you can't at this point set that in the
        connection string) - problem solved.</p>

    <p>Moral of the story: don't use deprecated code, especially if you are mixing it with the replacement!</p>

    <p>UPDATE: In version 2.9.0 of the java drivers you can set the Read Preference in the connection string - which
        will hopefully remove the temptation to use the deprecated option.</p>

</BlogPage>;