import {BlogPage} from "./BlogPage";
import React from "react";

export const GoodIntentions: React.FC = () => <BlogPage canonical="goodintentions" title='Good Intentions'>
    <p><i>Originally posted in 2012.</i></p>

    <p>I recently took part in a release retrospective for a project which had made it into production on time despite a
        number of challenges, albeit with significant functionality lying dormant because other teams had not managed to
        deliver planned integration points. For the team this could be regarded as a success, but on closer inspection
        we realised we could possibly have done things somewhat differently.</p>

    <p>The application in question was a REST API which provided functionality to be used in a number of user-facing web
        sites and we found we had written a perfectly acceptable REST API. Great - but we were not working for an API
        vendor! What the business actually needed was web sites with specific behaviours that the REST API was supposed
        to enable and so far this hasn't happened, so why could that be? It turned out that although the business was
        very keen to have the capability it hadn't fully thought through the specifics of what it wanted to achieve,
        which should have been obvious to us from the fact that we were not getting proper end to end user stories with
        demonstrable business value to play. We kept on asking for them, but sign-offs and design rework kept on pushing
        the final content back, then when it became clear that one of our major integration points was going to be very
        late the resulting changes in direction caused more delay. The result was that the full end-to-end stories which
        would validate our early API-only stories never actually arrived and we ended up putting the API into production
        still not knowing what was wanted in terms of user experience, not even really knowing if what we had written
        was going to meet the requirements as we still didn't really know the requirements.</p>

    <p>So what have I learned from this? Firstly that all the times I suggested we should go to the pub rather than work
        on stories we weren't sure about I was right - it wouldn't have been good for our livers but it might have
        focused our business owners on working out what they really needed. I also learned that the proverb "the road
        to hell is paved with good intentions" is absolutely true - we cut some slack to the product owner in the early
        stages of the project because we thought it would help to kick-start the project but in fact, especially as the
        project wasn't a very long one, the opposite was probably true; going to the pub for a fortnight might well have
        put enough focus on to creating end-to-end stories with demonstrable business value that we would have then been
        able to get on with developing the software to deliver that value instead of delivering exactly what we were
        asked to deliver but not necessarily what was actually needed.</p>

    <p>Mine's a pint of best, cheers!</p>

</BlogPage>;