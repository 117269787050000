import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Jumbotron, Navbar, NavbarBrand, NavDropdown, NavLink} from 'react-bootstrap';
import {BrowserRouter, Link, Route, Switch, useLocation} from 'react-router-dom';
import {Homepage} from "./Homepage";
import {Contact} from "./Contact";
import {BlogList} from "./Blog/BlogList";
import {NotFound} from "./NotFound";
import {TheValueOfWhy} from "./Blog/TheValueOfWhy";
import {CodeCoverageVsTestCoverage} from "./Blog/CodeCoverageVsTestCoverage";
import {MongoDbSlavesAreNotOk} from "./Blog/MongoDbSlavesAreNotOk";
import {GoodIntentions} from "./Blog/GoodIntentions";
import { getAnalytics, logEvent } from "firebase/analytics";

import * as firebase from 'firebase/app'
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyBudKXCxUhBpVh9DljBP-sEnrg-n71ynh0",
    authDomain: "bcl-web-e37c0.firebaseapp.com",
    projectId: "bcl-web-e37c0",
    storageBucket: "bcl-web-e37c0.appspot.com",
    messagingSenderId: "857512366092",
    appId: "1:857512366092:web:af40b9786d2ffc151b9e29",
    measurementId: "G-8T1ZYPRE74"
};

const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app)

function Hist() {
    const loc = useLocation();
    logEvent(analytics, "page_view", {page_path: loc.pathname})
    return <></>;
}

function App() {

    return (
        <BrowserRouter>
            <>
                <Hist/>
                <Navbar variant='dark' bg='dark' expand='lg'>
                    <NavbarBrand as={Link} to='/'>Barrett Consulting Limited</NavbarBrand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <NavLink as={Link} to='/'>Home</NavLink>
                        <NavDropdown title='Ramblings' as={Link} to='/ramblings' id='ramblings-dropdown'>
                            <NavDropdown.Item as={Link} to='/ramblings'>See all</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item as={Link} to='/ramblings/thevalueofwhy'>
                                The value of "Why?"
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/ramblings/codecoveragevstestcoverage'>
                                Code coverage vs test coverage
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/ramblings/mongodbslavesarenotok'>
                                MongoDB - slaves are not OK, and neither is using deprecated options...
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/ramblings/goodintentions'>
                                Good Intentions
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavLink as={Link} to='/contact'>Contact Us</NavLink>
                    </Navbar.Collapse>
                </Navbar>

                <Jumbotron>
                    <span className='text-center'>
                        <h1>Barrett Consulting Limited</h1>
                        <h4>Building software for change</h4>
                    </span>
                </Jumbotron>


                <Switch>
                    <Route path="/" exact component={Homepage}/>
                    <Route path="/ramblings" exact component={BlogList}/>
                    <Route path="/contact" exact component={Contact}/>
                    <Route path='/ramblings/thevalueofwhy' exact component={TheValueOfWhy}/>
                    <Route path='/ramblings/codecoveragevstestcoverage' exact component={CodeCoverageVsTestCoverage}/>
                    <Route path='/ramblings/mongodbslavesarenotok' exact component={MongoDbSlavesAreNotOk}/>
                    <Route path='/ramblings/goodintentions' exact component={GoodIntentions}/>
                    <Route path="" component={NotFound}/>
                </Switch>
            </>
        </BrowserRouter>
    );
}

export default App;
