import React from 'react'
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export const BlogList: React.FC = () => <>
    <Helmet>
        <title>Barrett Consulting Limited - Ramblings</title>
        <link rel="canonical" href="https://www.barrett-consulting.co.uk/ramblings" />
    </Helmet>

    <Container>
        <h1>Ramblings</h1>
        <p>Over the years I've learned, and sometimes re-learned, many valuable lessons both from my own mistakes and from watching the people around me. For the benefit of anyone who cares to read them, here are a few stories which may be of interest.</p>
        <p><Link to='/ramblings/thevalueofwhy'>The value of "Why?"</Link></p>
        <p><Link to='/ramblings/codecoveragevstestcoverage'>Code coverage vs test coverage</Link></p>
        <p><Link to='/ramblings/mongodbslavesarenotok'>MongoDB - slaves are not OK, and neither is using deprecated options...</Link></p>
        <p><Link to='/ramblings/goodintentions'>Good Intentions</Link></p>
    </Container>
</>;
