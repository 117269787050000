import React from 'react'
import {Container} from "react-bootstrap";
import {Helmet} from "react-helmet";

interface BlogProps {
    title: string;
    canonical: string
}

export const BlogPage: React.FC<BlogProps> = (props) => <>
    <Helmet>
        <title>Barrett Consulting Limited - Ramblings - {props.title}</title>
        <link rel="canonical" href={`https://www.barrett-consulting.co.uk/ramblings/${props.canonical}`} />
    </Helmet>

    <Container>
        <h1>{props.title}</h1>
        {props.children}
    </Container>
</>;
