import React from 'react'
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export const Homepage: React.FC = () => {
    return <>
        <Helmet>
            <title>Barrett Consulting Limited</title>
            <link rel="canonical" href="https://www.barrett-consulting.co.uk/" />
        </Helmet>

        <Container>
        <p>Barrett Consulting Limited is based in Reading (UK) and provides expert development and advice covering all
            aspects of the software lifecycle.</p>

        <p>We believe in building great software by applying a combination of agile, lean and extreme programming
            techniques. Our software architecture follows simple, secure and scalable patterns to create a solution that
            can grow with it's requirements.</p>

        <p>Companies we have worked (directly or indirectly) with in the past include:</p>
        <ul>
            <li>Equal Experts</li>
            <li>Thames Water</li>
            <li>Three</li>
            <li>The Home Office</li>
            <li>O2 including their Online Shop, O2 Drive and Authentication systems</li>
            <li>Visa Europe</li>
            <li>Thomson Local</li>
        </ul>

        <p>If you are interested in what we have to say about software development, head over to our page of ramblings.</p>

        <p>You can get in touch with us <Link to='/contact'>here</Link>.</p>

        </Container>
    </>;
};
